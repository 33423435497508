import api from '@apiInstance';

export default {
  add(data) {
    return api.post('order_headers', data);
  },
  get(id) {
    return api.fetch(`order_headers/${id}/detail`);
  },
  list(params) {
    return api.fetch('/order_headers', { params });
  },
  update(data) {
    return api.patch('order_headers', data);
  },
  delete(id) {
    return api.remove('order_headers', id);
  },

  updateOrderStatus(body) {
    return api.request({
      url: `/order_headers/${body.id}/updateorderstatus`,
      method: 'PATCH',
      body,
      type: 'order_headers',
    });
  },
  saveToDelivery(id, body) {
    return api.request({
      url: `/order_headers/${id}/savetodeliveries`,
      method: 'POST',
      body,
      type: 'order_headers',
    });
  },
  generatePDF(id) {
    return api.fetch(`order_headers/${id}/generatepdf`);
  },
};

<template>
  <section id="dashboard-ecommerce">
    <b-row>
      <b-col>
        <b-card>
          <b-card-text>
            <DxGrid
              title="Orders"
              :data-source="dataSource"
              @on-delete="(v) => onDelete(v)"
              @on-open-modal="(v) => onManage(v)"
              @on-update-cancel="(v) => loadOrders()"
            >
              <template #action-buttons>
                <DxButton icon="textdocument" hint="View" :onClick="onView" />
              </template>
              <template #columns>
                <DxColumn data-field="orderno" caption="Order No." />
                <DxColumn data-field="preview_order_date" caption="Order No." />
                <DxColumn data-field="customers_company" caption="Customer" />
                <DxColumn
                  data-field="preview_order_status"
                  caption="Order Status"
                />
              </template>
            </DxGrid>
          </b-card-text>
        </b-card>
      </b-col>
    </b-row>
  </section>
</template>

<script>
import orderApi from '@api/orders';
// @todo change from utils classes to global-function
import { unixToDisplayDateTime } from '@/utils/classes/dates';
import { queryParameters } from '@/schema';

export default {
  name: 'OrdersPage',
  components: {},
  data: () => ({
    dataSource: [],
  }),
  mounted() {
    this.loadOrders();
  },
  methods: {
    loadOrders() {
      const params = {
        sort: queryParameters.sort,
        page: queryParameters.page,
        status: queryParameters.status,
      };

      orderApi
        .list(params)
        .then(({ data }) => {
          data.forEach((e) => {
            e.preview_order_date = unixToDisplayDateTime(e.orderdate);

            e.preview_order_status = this.getOrderStatus(e.orderstatus);
          });
          this.dataSource = data;
        })
        .catch(() => {
          //
        })
        .finally(() => {
          //
        });
    },
    onView(event) {
      const url = `/pages/e-commerce/orders/view?id=${event.row.data.id}`;
      this.$router.push(url);
    },
    onDelete(event) {
      orderApi
        .delete(event)
        .then(({ data }) => {
          this.loadOrders();
        })
        .catch(() => {
          //
        })
        .finally(() => {
          //
        });
    },
    getOrderStatus(status) {
      if (status === '1') {
        return 'Processing';
      }

      if (status === '2') {
        return 'Ready To Deliver';
      }

      if (status === '3') {
        return 'OTW to Customer';
      }

      if (status === '4') {
        return 'Delivered';
      }

      if (status === '5') {
        return 'Cancelled';
      }

      return 'New';
    },
  },
};
</script>

<style></style>
